export function declOfNum (number, words) {
  return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]]
}

export function moreoverRound (number) {
  return number - number % (
    number < 10 ? 5 :
    number < 100 ? 10 :
    number < 500 ? 50
      : 100
  )
}

export function trim (str, limit) {
  if (str.length < limit) return str
  str = str.slice(0, str.indexOf('. '))
  if (str.length < limit) return str
  str = str.slice(0, str.indexOf('.'))
  if (str.length < limit) return str
  return str.slice(0, limit - 3) + '...'
}

export function formatLink (str) {
  return str
    .replace(/^https?:\/\/(www\.)?/, '')
    .replace(/\?.*$/, '')
}
